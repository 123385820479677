
    <template>
      <section class="content element-doc">
        <h2>Layout 布局</h2>
<p>通过基础的 24 分栏，迅速简便地创建布局。</p>
<h3>基础布局</h3>
<p>使用单一分栏创建基础的栅格布局。</p>
<demo-block>
        <div><p>通过 row 和 col 组件，并通过 col 组件的 <code>span</code> 属性我们就可以自由地组合布局。</p>
</div>
        <template #source><element-demo0 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;el-row&gt;
    &lt;el-col :span=&quot;24&quot;&gt;&lt;div class=&quot;grid-content bg-purple-dark&quot;&gt;&lt;/div&gt;&lt;/el-col&gt;
  &lt;/el-row&gt;
  &lt;el-row&gt;
    &lt;el-col :span=&quot;12&quot;&gt;&lt;div class=&quot;grid-content bg-purple&quot;&gt;&lt;/div&gt;&lt;/el-col&gt;
    &lt;el-col :span=&quot;12&quot;&gt;&lt;div class=&quot;grid-content bg-purple-light&quot;&gt;&lt;/div&gt;&lt;/el-col&gt;
  &lt;/el-row&gt;
  &lt;el-row&gt;
    &lt;el-col :span=&quot;8&quot;&gt;&lt;div class=&quot;grid-content bg-purple&quot;&gt;&lt;/div&gt;&lt;/el-col&gt;
    &lt;el-col :span=&quot;8&quot;&gt;&lt;div class=&quot;grid-content bg-purple-light&quot;&gt;&lt;/div&gt;&lt;/el-col&gt;
    &lt;el-col :span=&quot;8&quot;&gt;&lt;div class=&quot;grid-content bg-purple&quot;&gt;&lt;/div&gt;&lt;/el-col&gt;
  &lt;/el-row&gt;
  &lt;el-row&gt;
    &lt;el-col :span=&quot;6&quot;&gt;&lt;div class=&quot;grid-content bg-purple&quot;&gt;&lt;/div&gt;&lt;/el-col&gt;
    &lt;el-col :span=&quot;6&quot;&gt;&lt;div class=&quot;grid-content bg-purple-light&quot;&gt;&lt;/div&gt;&lt;/el-col&gt;
    &lt;el-col :span=&quot;6&quot;&gt;&lt;div class=&quot;grid-content bg-purple&quot;&gt;&lt;/div&gt;&lt;/el-col&gt;
    &lt;el-col :span=&quot;6&quot;&gt;&lt;div class=&quot;grid-content bg-purple-light&quot;&gt;&lt;/div&gt;&lt;/el-col&gt;
  &lt;/el-row&gt;
  &lt;el-row&gt;
    &lt;el-col :span=&quot;4&quot;&gt;&lt;div class=&quot;grid-content bg-purple&quot;&gt;&lt;/div&gt;&lt;/el-col&gt;
    &lt;el-col :span=&quot;4&quot;&gt;&lt;div class=&quot;grid-content bg-purple-light&quot;&gt;&lt;/div&gt;&lt;/el-col&gt;
    &lt;el-col :span=&quot;4&quot;&gt;&lt;div class=&quot;grid-content bg-purple&quot;&gt;&lt;/div&gt;&lt;/el-col&gt;
    &lt;el-col :span=&quot;4&quot;&gt;&lt;div class=&quot;grid-content bg-purple-light&quot;&gt;&lt;/div&gt;&lt;/el-col&gt;
    &lt;el-col :span=&quot;4&quot;&gt;&lt;div class=&quot;grid-content bg-purple&quot;&gt;&lt;/div&gt;&lt;/el-col&gt;
    &lt;el-col :span=&quot;4&quot;&gt;&lt;div class=&quot;grid-content bg-purple-light&quot;&gt;&lt;/div&gt;&lt;/el-col&gt;
  &lt;/el-row&gt;
&lt;/template&gt;
&lt;style&gt;
  .el-row {
    margin-bottom: 20px;
    &amp;:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
&lt;/style&gt;
</code></pre></template></demo-block><h3>分栏间隔</h3>
<p>分栏之间存在间隔。</p>
<demo-block>
        <div><p>Row 组件 提供 <code>gutter</code> 属性来指定每一栏之间的间隔，默认间隔为 0。</p>
</div>
        <template #source><element-demo1 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;el-row :gutter=&quot;20&quot;&gt;
    &lt;el-col :span=&quot;6&quot;&gt;&lt;div class=&quot;grid-content bg-purple&quot;&gt;&lt;/div&gt;&lt;/el-col&gt;
    &lt;el-col :span=&quot;6&quot;&gt;&lt;div class=&quot;grid-content bg-purple&quot;&gt;&lt;/div&gt;&lt;/el-col&gt;
    &lt;el-col :span=&quot;6&quot;&gt;&lt;div class=&quot;grid-content bg-purple&quot;&gt;&lt;/div&gt;&lt;/el-col&gt;
    &lt;el-col :span=&quot;6&quot;&gt;&lt;div class=&quot;grid-content bg-purple&quot;&gt;&lt;/div&gt;&lt;/el-col&gt;
  &lt;/el-row&gt;
&lt;/template&gt;
&lt;style&gt;
  .el-row {
    margin-bottom: 20px;
    &amp;:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
&lt;/style&gt;
</code></pre></template></demo-block><h3>混合布局</h3>
<p>通过基础的 1/24 分栏任意扩展组合形成较为复杂的混合布局。</p>
<demo-block>
        
        <template #source><element-demo2 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;el-row :gutter=&quot;20&quot;&gt;
    &lt;el-col :span=&quot;16&quot;&gt;&lt;div class=&quot;grid-content bg-purple&quot;&gt;&lt;/div&gt;&lt;/el-col&gt;
    &lt;el-col :span=&quot;8&quot;&gt;&lt;div class=&quot;grid-content bg-purple&quot;&gt;&lt;/div&gt;&lt;/el-col&gt;
  &lt;/el-row&gt;
  &lt;el-row :gutter=&quot;20&quot;&gt;
    &lt;el-col :span=&quot;8&quot;&gt;&lt;div class=&quot;grid-content bg-purple&quot;&gt;&lt;/div&gt;&lt;/el-col&gt;
    &lt;el-col :span=&quot;8&quot;&gt;&lt;div class=&quot;grid-content bg-purple&quot;&gt;&lt;/div&gt;&lt;/el-col&gt;
    &lt;el-col :span=&quot;4&quot;&gt;&lt;div class=&quot;grid-content bg-purple&quot;&gt;&lt;/div&gt;&lt;/el-col&gt;
    &lt;el-col :span=&quot;4&quot;&gt;&lt;div class=&quot;grid-content bg-purple&quot;&gt;&lt;/div&gt;&lt;/el-col&gt;
  &lt;/el-row&gt;
  &lt;el-row :gutter=&quot;20&quot;&gt;
    &lt;el-col :span=&quot;4&quot;&gt;&lt;div class=&quot;grid-content bg-purple&quot;&gt;&lt;/div&gt;&lt;/el-col&gt;
    &lt;el-col :span=&quot;16&quot;&gt;&lt;div class=&quot;grid-content bg-purple&quot;&gt;&lt;/div&gt;&lt;/el-col&gt;
    &lt;el-col :span=&quot;4&quot;&gt;&lt;div class=&quot;grid-content bg-purple&quot;&gt;&lt;/div&gt;&lt;/el-col&gt;
  &lt;/el-row&gt;
&lt;/template&gt;
&lt;style&gt;
  .el-row {
    margin-bottom: 20px;
    &amp;:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
&lt;/style&gt;
</code></pre></template></demo-block><h3>分栏偏移</h3>
<p>支持偏移指定的栏数。</p>
<demo-block>
        <div><p>通过制定 col 组件的 <code>offset</code> 属性可以指定分栏偏移的栏数。</p>
</div>
        <template #source><element-demo3 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;el-row :gutter=&quot;20&quot;&gt;
    &lt;el-col :span=&quot;6&quot;&gt;&lt;div class=&quot;grid-content bg-purple&quot;&gt;&lt;/div&gt;&lt;/el-col&gt;
    &lt;el-col :span=&quot;6&quot; :offset=&quot;6&quot;
      &gt;&lt;div class=&quot;grid-content bg-purple&quot;&gt;&lt;/div
    &gt;&lt;/el-col&gt;
  &lt;/el-row&gt;
  &lt;el-row :gutter=&quot;20&quot;&gt;
    &lt;el-col :span=&quot;6&quot; :offset=&quot;6&quot;
      &gt;&lt;div class=&quot;grid-content bg-purple&quot;&gt;&lt;/div
    &gt;&lt;/el-col&gt;
    &lt;el-col :span=&quot;6&quot; :offset=&quot;6&quot;
      &gt;&lt;div class=&quot;grid-content bg-purple&quot;&gt;&lt;/div
    &gt;&lt;/el-col&gt;
  &lt;/el-row&gt;
  &lt;el-row :gutter=&quot;20&quot;&gt;
    &lt;el-col :span=&quot;12&quot; :offset=&quot;6&quot;
      &gt;&lt;div class=&quot;grid-content bg-purple&quot;&gt;&lt;/div
    &gt;&lt;/el-col&gt;
  &lt;/el-row&gt;
&lt;/template&gt;
&lt;style&gt;
  .el-row {
    margin-bottom: 20px;
    &amp;:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
&lt;/style&gt;
</code></pre></template></demo-block><h3>对齐方式</h3>
<p>通过 <code>flex</code> 布局来对分栏进行灵活的对齐。</p>
<demo-block>
        <div><p>将 <code>type</code> 属性赋值为 'flex'，可以启用 flex 布局，并可通过 <code>justify</code> 属性来指定 start, center, end, space-between, space-around 其中的值来定义子元素的排版方式。</p>
</div>
        <template #source><element-demo4 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;el-row type=&quot;flex&quot; class=&quot;row-bg&quot;&gt;
    &lt;el-col :span=&quot;6&quot;&gt;&lt;div class=&quot;grid-content bg-purple&quot;&gt;&lt;/div&gt;&lt;/el-col&gt;
    &lt;el-col :span=&quot;6&quot;&gt;&lt;div class=&quot;grid-content bg-purple-light&quot;&gt;&lt;/div&gt;&lt;/el-col&gt;
    &lt;el-col :span=&quot;6&quot;&gt;&lt;div class=&quot;grid-content bg-purple&quot;&gt;&lt;/div&gt;&lt;/el-col&gt;
  &lt;/el-row&gt;
  &lt;el-row type=&quot;flex&quot; class=&quot;row-bg&quot; justify=&quot;center&quot;&gt;
    &lt;el-col :span=&quot;6&quot;&gt;&lt;div class=&quot;grid-content bg-purple&quot;&gt;&lt;/div&gt;&lt;/el-col&gt;
    &lt;el-col :span=&quot;6&quot;&gt;&lt;div class=&quot;grid-content bg-purple-light&quot;&gt;&lt;/div&gt;&lt;/el-col&gt;
    &lt;el-col :span=&quot;6&quot;&gt;&lt;div class=&quot;grid-content bg-purple&quot;&gt;&lt;/div&gt;&lt;/el-col&gt;
  &lt;/el-row&gt;
  &lt;el-row type=&quot;flex&quot; class=&quot;row-bg&quot; justify=&quot;end&quot;&gt;
    &lt;el-col :span=&quot;6&quot;&gt;&lt;div class=&quot;grid-content bg-purple&quot;&gt;&lt;/div&gt;&lt;/el-col&gt;
    &lt;el-col :span=&quot;6&quot;&gt;&lt;div class=&quot;grid-content bg-purple-light&quot;&gt;&lt;/div&gt;&lt;/el-col&gt;
    &lt;el-col :span=&quot;6&quot;&gt;&lt;div class=&quot;grid-content bg-purple&quot;&gt;&lt;/div&gt;&lt;/el-col&gt;
  &lt;/el-row&gt;
  &lt;el-row type=&quot;flex&quot; class=&quot;row-bg&quot; justify=&quot;space-between&quot;&gt;
    &lt;el-col :span=&quot;6&quot;&gt;&lt;div class=&quot;grid-content bg-purple&quot;&gt;&lt;/div&gt;&lt;/el-col&gt;
    &lt;el-col :span=&quot;6&quot;&gt;&lt;div class=&quot;grid-content bg-purple-light&quot;&gt;&lt;/div&gt;&lt;/el-col&gt;
    &lt;el-col :span=&quot;6&quot;&gt;&lt;div class=&quot;grid-content bg-purple&quot;&gt;&lt;/div&gt;&lt;/el-col&gt;
  &lt;/el-row&gt;
  &lt;el-row type=&quot;flex&quot; class=&quot;row-bg&quot; justify=&quot;space-around&quot;&gt;
    &lt;el-col :span=&quot;6&quot;&gt;&lt;div class=&quot;grid-content bg-purple&quot;&gt;&lt;/div&gt;&lt;/el-col&gt;
    &lt;el-col :span=&quot;6&quot;&gt;&lt;div class=&quot;grid-content bg-purple-light&quot;&gt;&lt;/div&gt;&lt;/el-col&gt;
    &lt;el-col :span=&quot;6&quot;&gt;&lt;div class=&quot;grid-content bg-purple&quot;&gt;&lt;/div&gt;&lt;/el-col&gt;
  &lt;/el-row&gt;
&lt;/template&gt;
&lt;style&gt;
  .el-row {
    margin-bottom: 20px;
    &amp;:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
&lt;/style&gt;
</code></pre></template></demo-block><h3>响应式布局</h3>
<p>参照了 Bootstrap 的 响应式设计，预设了五个响应尺寸：<code>xs</code>、<code>sm</code>、<code>md</code>、<code>lg</code> 和 <code>xl</code>。</p>
<demo-block>
        
        <template #source><element-demo5 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;el-row :gutter=&quot;10&quot;&gt;
    &lt;el-col :xs=&quot;8&quot; :sm=&quot;6&quot; :md=&quot;4&quot; :lg=&quot;3&quot; :xl=&quot;1&quot;
      &gt;&lt;div class=&quot;grid-content bg-purple&quot;&gt;&lt;/div
    &gt;&lt;/el-col&gt;
    &lt;el-col :xs=&quot;4&quot; :sm=&quot;6&quot; :md=&quot;8&quot; :lg=&quot;9&quot; :xl=&quot;11&quot;
      &gt;&lt;div class=&quot;grid-content bg-purple-light&quot;&gt;&lt;/div
    &gt;&lt;/el-col&gt;
    &lt;el-col :xs=&quot;4&quot; :sm=&quot;6&quot; :md=&quot;8&quot; :lg=&quot;9&quot; :xl=&quot;11&quot;
      &gt;&lt;div class=&quot;grid-content bg-purple&quot;&gt;&lt;/div
    &gt;&lt;/el-col&gt;
    &lt;el-col :xs=&quot;8&quot; :sm=&quot;6&quot; :md=&quot;4&quot; :lg=&quot;3&quot; :xl=&quot;1&quot;
      &gt;&lt;div class=&quot;grid-content bg-purple-light&quot;&gt;&lt;/div
    &gt;&lt;/el-col&gt;
  &lt;/el-row&gt;
&lt;/template&gt;
&lt;style&gt;
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
&lt;/style&gt;
</code></pre></template></demo-block><h3>基于断点的隐藏类</h3>
<p>Element 额外提供了一系列类名，用于在某些条件下隐藏元素。这些类名可以添加在任何 DOM 元素或自定义组件上。如果需要，请自行引入以下文件：</p>
<pre><code class="hljs language-js"><span class="hljs-keyword">import</span> <span class="hljs-string">&#x27;element3/lib/theme-chalk/display.css&#x27;</span>
</code></pre>
<p>包含的类名及其含义为：</p>
<ul>
<li><code>hidden-xs-only</code> - 当视口在 <code>xs</code> 尺寸时隐藏</li>
<li><code>hidden-sm-only</code> - 当视口在 <code>sm</code> 尺寸时隐藏</li>
<li><code>hidden-sm-and-down</code> - 当视口在 <code>sm</code> 及以下尺寸时隐藏</li>
<li><code>hidden-sm-and-up</code> - 当视口在 <code>sm</code> 及以上尺寸时隐藏</li>
<li><code>hidden-md-only</code> - 当视口在 <code>md</code> 尺寸时隐藏</li>
<li><code>hidden-md-and-down</code> - 当视口在 <code>md</code> 及以下尺寸时隐藏</li>
<li><code>hidden-md-and-up</code> - 当视口在 <code>md</code> 及以上尺寸时隐藏</li>
<li><code>hidden-lg-only</code> - 当视口在 <code>lg</code> 尺寸时隐藏</li>
<li><code>hidden-lg-and-down</code> - 当视口在 <code>lg</code> 及以下尺寸时隐藏</li>
<li><code>hidden-lg-and-up</code> - 当视口在 <code>lg</code> 及以上尺寸时隐藏</li>
<li><code>hidden-xl-only</code> - 当视口在 <code>xl</code> 尺寸时隐藏</li>
</ul>
<h3>Row Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>gutter</td>
<td>栅格间隔</td>
<td>number</td>
<td>—</td>
<td>0</td>
</tr>
<tr>
<td>type</td>
<td>布局模式，可选 flex，现代浏览器下有效</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>justify</td>
<td>flex 布局下的水平排列方式</td>
<td>string</td>
<td>start/end/center/space-around/space-between</td>
<td>start</td>
</tr>
<tr>
<td>align</td>
<td>flex 布局下的垂直排列方式</td>
<td>string</td>
<td>top/middle/bottom</td>
<td>top</td>
</tr>
<tr>
<td>tag</td>
<td>自定义元素标签</td>
<td>string</td>
<td>*</td>
<td>div</td>
</tr>
</tbody>
</table>
<h3>Col Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>span</td>
<td>栅格占据的列数</td>
<td>number</td>
<td>—</td>
<td>24</td>
</tr>
<tr>
<td>offset</td>
<td>栅格左侧的间隔格数</td>
<td>number</td>
<td>—</td>
<td>0</td>
</tr>
<tr>
<td>push</td>
<td>栅格向右移动格数</td>
<td>number</td>
<td>—</td>
<td>0</td>
</tr>
<tr>
<td>pull</td>
<td>栅格向左移动格数</td>
<td>number</td>
<td>—</td>
<td>0</td>
</tr>
<tr>
<td>xs</td>
<td><code>&lt;768px</code> 响应式栅格数或者栅格属性对象</td>
<td>number/object (例如： {span: 4, offset: 4})</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>sm</td>
<td><code>≥768px</code> 响应式栅格数或者栅格属性对象</td>
<td>number/object (例如： {span: 4, offset: 4})</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>md</td>
<td><code>≥992px</code> 响应式栅格数或者栅格属性对象</td>
<td>number/object (例如： {span: 4, offset: 4})</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>lg</td>
<td><code>≥1200px</code> 响应式栅格数或者栅格属性对象</td>
<td>number/object (例如： {span: 4, offset: 4})</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>xl</td>
<td><code>≥1920px</code> 响应式栅格数或者栅格属性对象</td>
<td>number/object (例如： {span: 4, offset: 4})</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>tag</td>
<td>自定义元素标签</td>
<td>string</td>
<td>*</td>
<td>div</td>
</tr>
</tbody>
</table>

      </section>
    </template>
    <script>
      import hljs from 'highlight.js'
      import * as Vue from "vue"
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": (function() {
    
    const { createVNode: _createVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_col = _resolveComponent("el-col")
  const _component_el_row = _resolveComponent("el-row")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 24 }, {
          default: _withCtx(() => [
            _createVNode("div", { class: "grid-content bg-purple-dark" })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 12 }, {
          default: _withCtx(() => [
            _createVNode("div", { class: "grid-content bg-purple" })
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 12 }, {
          default: _withCtx(() => [
            _createVNode("div", { class: "grid-content bg-purple-light" })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 8 }, {
          default: _withCtx(() => [
            _createVNode("div", { class: "grid-content bg-purple" })
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 8 }, {
          default: _withCtx(() => [
            _createVNode("div", { class: "grid-content bg-purple-light" })
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 8 }, {
          default: _withCtx(() => [
            _createVNode("div", { class: "grid-content bg-purple" })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 6 }, {
          default: _withCtx(() => [
            _createVNode("div", { class: "grid-content bg-purple" })
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 6 }, {
          default: _withCtx(() => [
            _createVNode("div", { class: "grid-content bg-purple-light" })
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 6 }, {
          default: _withCtx(() => [
            _createVNode("div", { class: "grid-content bg-purple" })
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 6 }, {
          default: _withCtx(() => [
            _createVNode("div", { class: "grid-content bg-purple-light" })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 4 }, {
          default: _withCtx(() => [
            _createVNode("div", { class: "grid-content bg-purple" })
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 4 }, {
          default: _withCtx(() => [
            _createVNode("div", { class: "grid-content bg-purple-light" })
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 4 }, {
          default: _withCtx(() => [
            _createVNode("div", { class: "grid-content bg-purple" })
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 4 }, {
          default: _withCtx(() => [
            _createVNode("div", { class: "grid-content bg-purple-light" })
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 4 }, {
          default: _withCtx(() => [
            _createVNode("div", { class: "grid-content bg-purple" })
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 4 }, {
          default: _withCtx(() => [
            _createVNode("div", { class: "grid-content bg-purple-light" })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo1": (function() {
    
    const { createVNode: _createVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_col = _resolveComponent("el-col")
  const _component_el_row = _resolveComponent("el-row")

  return (_openBlock(), _createBlock(_component_el_row, { gutter: 20 }, {
    default: _withCtx(() => [
      _createVNode(_component_el_col, { span: 6 }, {
        default: _withCtx(() => [
          _createVNode("div", { class: "grid-content bg-purple" })
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, { span: 6 }, {
        default: _withCtx(() => [
          _createVNode("div", { class: "grid-content bg-purple" })
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, { span: 6 }, {
        default: _withCtx(() => [
          _createVNode("div", { class: "grid-content bg-purple" })
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, { span: 6 }, {
        default: _withCtx(() => [
          _createVNode("div", { class: "grid-content bg-purple" })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo2": (function() {
    
    const { createVNode: _createVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_col = _resolveComponent("el-col")
  const _component_el_row = _resolveComponent("el-row")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_row, { gutter: 20 }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 16 }, {
          default: _withCtx(() => [
            _createVNode("div", { class: "grid-content bg-purple" })
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 8 }, {
          default: _withCtx(() => [
            _createVNode("div", { class: "grid-content bg-purple" })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, { gutter: 20 }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 8 }, {
          default: _withCtx(() => [
            _createVNode("div", { class: "grid-content bg-purple" })
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 8 }, {
          default: _withCtx(() => [
            _createVNode("div", { class: "grid-content bg-purple" })
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 4 }, {
          default: _withCtx(() => [
            _createVNode("div", { class: "grid-content bg-purple" })
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 4 }, {
          default: _withCtx(() => [
            _createVNode("div", { class: "grid-content bg-purple" })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, { gutter: 20 }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 4 }, {
          default: _withCtx(() => [
            _createVNode("div", { class: "grid-content bg-purple" })
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 16 }, {
          default: _withCtx(() => [
            _createVNode("div", { class: "grid-content bg-purple" })
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 4 }, {
          default: _withCtx(() => [
            _createVNode("div", { class: "grid-content bg-purple" })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo3": (function() {
    
    const { createVNode: _createVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_col = _resolveComponent("el-col")
  const _component_el_row = _resolveComponent("el-row")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_row, { gutter: 20 }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 6 }, {
          default: _withCtx(() => [
            _createVNode("div", { class: "grid-content bg-purple" })
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, {
          span: 6,
          offset: 6
        }, {
          default: _withCtx(() => [
            _createVNode("div", { class: "grid-content bg-purple" })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, { gutter: 20 }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, {
          span: 6,
          offset: 6
        }, {
          default: _withCtx(() => [
            _createVNode("div", { class: "grid-content bg-purple" })
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, {
          span: 6,
          offset: 6
        }, {
          default: _withCtx(() => [
            _createVNode("div", { class: "grid-content bg-purple" })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, { gutter: 20 }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, {
          span: 12,
          offset: 6
        }, {
          default: _withCtx(() => [
            _createVNode("div", { class: "grid-content bg-purple" })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo4": (function() {
    
    const { createVNode: _createVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_col = _resolveComponent("el-col")
  const _component_el_row = _resolveComponent("el-row")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_row, {
      type: "flex",
      class: "row-bg"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 6 }, {
          default: _withCtx(() => [
            _createVNode("div", { class: "grid-content bg-purple" })
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 6 }, {
          default: _withCtx(() => [
            _createVNode("div", { class: "grid-content bg-purple-light" })
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 6 }, {
          default: _withCtx(() => [
            _createVNode("div", { class: "grid-content bg-purple" })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, {
      type: "flex",
      class: "row-bg",
      justify: "center"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 6 }, {
          default: _withCtx(() => [
            _createVNode("div", { class: "grid-content bg-purple" })
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 6 }, {
          default: _withCtx(() => [
            _createVNode("div", { class: "grid-content bg-purple-light" })
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 6 }, {
          default: _withCtx(() => [
            _createVNode("div", { class: "grid-content bg-purple" })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, {
      type: "flex",
      class: "row-bg",
      justify: "end"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 6 }, {
          default: _withCtx(() => [
            _createVNode("div", { class: "grid-content bg-purple" })
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 6 }, {
          default: _withCtx(() => [
            _createVNode("div", { class: "grid-content bg-purple-light" })
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 6 }, {
          default: _withCtx(() => [
            _createVNode("div", { class: "grid-content bg-purple" })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, {
      type: "flex",
      class: "row-bg",
      justify: "space-between"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 6 }, {
          default: _withCtx(() => [
            _createVNode("div", { class: "grid-content bg-purple" })
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 6 }, {
          default: _withCtx(() => [
            _createVNode("div", { class: "grid-content bg-purple-light" })
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 6 }, {
          default: _withCtx(() => [
            _createVNode("div", { class: "grid-content bg-purple" })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, {
      type: "flex",
      class: "row-bg",
      justify: "space-around"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 6 }, {
          default: _withCtx(() => [
            _createVNode("div", { class: "grid-content bg-purple" })
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 6 }, {
          default: _withCtx(() => [
            _createVNode("div", { class: "grid-content bg-purple-light" })
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 6 }, {
          default: _withCtx(() => [
            _createVNode("div", { class: "grid-content bg-purple" })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo5": (function() {
    
    const { createVNode: _createVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_col = _resolveComponent("el-col")
  const _component_el_row = _resolveComponent("el-row")

  return (_openBlock(), _createBlock(_component_el_row, { gutter: 10 }, {
    default: _withCtx(() => [
      _createVNode(_component_el_col, {
        xs: 8,
        sm: 6,
        md: 4,
        lg: 3,
        xl: 1
      }, {
        default: _withCtx(() => [
          _createVNode("div", { class: "grid-content bg-purple" })
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, {
        xs: 4,
        sm: 6,
        md: 8,
        lg: 9,
        xl: 11
      }, {
        default: _withCtx(() => [
          _createVNode("div", { class: "grid-content bg-purple-light" })
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, {
        xs: 4,
        sm: 6,
        md: 8,
        lg: 9,
        xl: 11
      }, {
        default: _withCtx(() => [
          _createVNode("div", { class: "grid-content bg-purple" })
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, {
        xs: 8,
        sm: 6,
        md: 4,
        lg: 3,
        xl: 1
      }, {
        default: _withCtx(() => [
          _createVNode("div", { class: "grid-content bg-purple-light" })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),
        }
      }
    </script>
  